import React, {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Start from "./views/start/Start";
import AuthView from "./views/auth/AuthView";
import DashBoard from "./views/dashboard/Dashboard";
import './App.css';

function App() {
  return (
    <Router >
      <Routes >
        <Route path='/' element={<Start />} />
        <Route path='/login' element={<AuthView />} />
        <Route path='/dashboard' element={<DashBoard />} />
      </Routes>
    </Router>
  );
}

export default App;
