import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import { plantInfoNew } from "../utils/enums";
import { firebaseAuth } from "../firebase/auth";

import { ActivePlantIF, PlantArrayIF } from "../interfaces/Interfaces";
import "./dashboard.css";

type TsProps = {
  plant: ActivePlantIF;
  handleCheckChange(arg0: boolean, arg1: string): void;
  handleEditOpen(arg0: number): void;
  plantIndex: number;
};

const DarkItem = ({
  plant,
  handleCheckChange,
  handleEditOpen,
  plantIndex,
}: TsProps) => {

  return (
    <div className="AddPlantDialogBottomItem">
      <Checkbox
        size="small"
        sx={{
          color: "grey",
          "&.Mui-checked": {
            color: "#44a99a",
          },
        }}
        checked={plant.checked}
        onChange={(event) => handleCheckChange(event.target.checked, plant.firebaseId)}
        inputProps={{ "aria-label": "controlled" }}
      />
      <img
        src={
          // @ts-ignore
          plantInfoNew.find((element) => element.id === plant.id).url
        }
        alt={
          // @ts-ignore
          plantInfoNew.find((element) => element.id === plant.id).name[0]
        }
        width={80}
        height={80}
      />
      <div className="PlantListItemTextOuter">
        <p className="AddPlantDialogBottomItemText">
          {
            // @ts-ignore
            plantInfoNew.find((element) => element.id === plant.id).name[0]
          }
        </p>
        <p className="PlantListSubtitle">
          {plant.amount} Fat - Planterad av {plant.plantedBy}{" "}
          för {Math.floor((Date.now() - Number(plant.planted)) / 86400000)}{" "}
          dagar sedan
        </p>
      </div>
      <div className="PlantListItemDays">
        <p className="PlantListItemDaysLarge">
          {Math.floor((Date.now() - Number(plant.planted)) / 86400000)}
        </p>
        <p className="PlantListItemDaysSmall">DAGAR</p>
      </div>
      <IconButton color="inherit" onClick={() => handleEditOpen(plantIndex)}>
        <EditIcon color="inherit" />
      </IconButton>
    </div>
  );
};

export default DarkItem;
