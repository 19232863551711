import React, { useEffect, useState, ChangeEvent } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  doc as fsDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import {
  MonitorIF,
  MonitorDBIF,
} from "../../../components/interfaces/Interfaces";
import { firebaseAuth } from "../../../components/firebase/auth";
import { firebaseDB } from "../../trace/firestore";
import moment from "moment";
import "moment/locale/sv";
import "./control.css";

moment.locale("sv");

type TsProps = {
  item: MonitorIF;
};
function ControlValueItem({ item }: TsProps) {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [dbData, setDbData] = useState<MonitorDBIF | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [firebaseId, setFirebaseId] = useState("");

  const registerDate = async () => {
    console.log(Date.now());

    const tempValue = Date.now();
    await addDoc(collection(firebaseDB, "monitor", item.id, "monitorData"), {
      id: item.id,
      value: inputValue,
      date: tempValue,
    });
    setInputValue('')
  };

  const deleteDate = async () => {
    await deleteDoc(
      fsDoc(firebaseDB, "monitor", item.id, "monitorData", firebaseId)
    );
  };

  useEffect(() => {
    const q = query(
      collection(firebaseDB, "monitor", item.id, "monitorData"),
      orderBy("date", "desc"),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let count = 0;
      querySnapshot.forEach((snap) => {
        if (count === 0) {
          setDbData({
            id: snap.data().id,
            date: snap.data().date,
            value: snap.data().value,
          });
          setFirebaseId(snap.id);
        }
        count += 1;
      });
    });
    return () => unsubscribe();
  }, []);

  console.log(dbData);
  return (
    <div className="ControlItemOuter">
      <div className="ControlItemInner">
        <p className="ControlItemTitle">{item.name}</p>
        {dbData !== null ? (
          <p className="ControlItemText">
            {moment(dbData.date).format("YYYY-MM-DD - HH:mm") +
              " - " +
              moment(dbData.date).fromNow() +
              " - " +
              item.valueType +
              dbData.value}
          </p>
        ) : null}
      </div>
      <TextField
        size="small"
        variant="standard"
        sx={{width: '50px', marginRight: '10px'}}
        value={inputValue}
        onChange={(e:ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
      />
      {dbData !== null && Date.now() - Number(dbData.date) > 60000 ? (
        <Button
          onClick={registerDate}
          size="small"
          color="inherit"
          variant="outlined"
          disabled={inputValue === ''}
        >
          REGISTRERA
        </Button>
      ) : null}
      {dbData === null ? (
        <Button
          onClick={registerDate}
          size="small"
          color="inherit"
          variant="outlined"
          disabled={inputValue === ''}
        >
          REGISTRERA
        </Button>
      ) : null}
      {dbData !== null && Date.now() - Number(dbData.date) < 60000 ? (
        <Button
          onClick={deleteDate}
          size="small"
          color="inherit"
          variant="outlined"
        >
          ÅNGRA
        </Button>
      ) : null}
    </div>
  );
}

export default ControlValueItem;
