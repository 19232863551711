import React, { useEffect, useState } from "react";

import { doc, collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { plantInfoNew } from "../../../components/utils/enums";
import { firebaseAuth } from "../../../components/firebase/auth";
import { firebaseDB } from "../../trace/firestore";

import { ActivePlantArrayIF, ActivePlantAmountArrayIF, ActivePlantAmountIF, PlantArrayIF } from "../../../components/interfaces/Interfaces";
import "./dashboardActive.css";

const plantedByArr = [
  "Ann-Helen",
  "Adrian",
  "Jimmy",
  "Ann-Helen & Adrian",
  "Ann-Helen & Jimmy",
  "Adrian & Jimmy",
  "Ann-Helen, Adrian & Jimmy",
];
function AddPlant() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<number | null>(null);
  const [plantedBy, setPlantedBy] = useState<string|null>(null);
  const [addedPlants, setAddedPlants] = useState<ActivePlantAmountArrayIF>([]);
  const [plants, setPlantsArr] = useState<PlantArrayIF | null>(null);
  const [dialogLoading, setDialogLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddPlantToArr = (plantIndex:number) => {
    if (plants === null) {
      return;
    }
    let tempArr = [...addedPlants];
    let tempObj = {
      amount: 1,
      plant: plants[plantIndex],
    };
    tempArr.push(tempObj);

    let tempArrPlants = [...plants];
    tempArrPlants.splice(plantIndex, 1);

    setAddedPlants(tempArr);
    setPlantsArr(tempArrPlants);
  };

  const handleRemovePlantFromArr = (plantIndex:number) => {
    if(plants === null) {
      return
    }
    let tempArrPlants = [...plants];
    tempArrPlants.push(addedPlants[plantIndex].plant);

    let tempArr = [...addedPlants];
    tempArr.splice(plantIndex, 1);

    setPlantsArr(tempArrPlants);
    setAddedPlants(tempArr);
  };

  const addAmount = (plantIndex:number) => {
    let tempArr = [...addedPlants];

    if (tempArr[plantIndex].amount >= 50) {
      return;
    }

    tempArr[plantIndex].amount += 1;

    setAddedPlants(tempArr);
  };

  const removeAmount = (plantIndex:number) => {
    let tempArr = [...addedPlants];

    if (tempArr[plantIndex].amount <= 1) {
      return;
    }
    tempArr[plantIndex].amount -= 1;
    setAddedPlants(tempArr);
  };

  useEffect(() => {
    if (plants === null) {
      setPlantsArr(plantInfoNew);
    }
  }, [plants]);

  const addToFirebase = async () => {
    if (date===null) {
      return
    }
    setDialogLoading(true);

    for (let i = 0; i < addedPlants.length; i++) {
        console.log(addedPlants[i])
      await addDoc(
        collection(
          firebaseDB,
          "plants",
          addedPlants[i].plant.id,
          "plantData"
        ),
        {
          id: addedPlants[i].plant.id,
          amount: addedPlants[i].amount,
          initAmount: addedPlants[i].amount,
          planted: date,
          plantedBy: plantedBy,
          lightActive: null,
          cool: addedPlants[i].plant.cool,
          ec: addedPlants[i].plant.ec,
          hum: addedPlants[i].plant.hum,
          light: addedPlants[i].plant.light,
          ph: addedPlants[i].plant.ph,
          pump: addedPlants[i].plant.pump,
          spray: addedPlants[i].plant.spray,
          temp: addedPlants[i].plant.temp,
          waterTemp: addedPlants[i].plant.waterTemp,
          end: null,
        }
      );
    }

      setOpen(false)
      setDialogLoading(false);
      setPlantedBy(null)
      setAddedPlants([])
      setDate(null)
      setPlantsArr(plantInfoNew)

  };

  return (
    <div className="AddPlant">
      <IconButton color="inherit" onClick={() => setOpen(true)}>
        <AddIcon color="inherit" />
      </IconButton>
      {plants !== null ? (
        <Dialog maxWidth="lg" open={open} onClose={handleClose}>
          <DialogTitle>Lägg till</DialogTitle>
          <DialogContent>
            <div className="AddPlantDialog">
              <p className="AddPlantDialogBottomItemText">PLANTERAD AV</p>
              <div className="AddPlantDialogTop">
                {plantedByArr.map((name, index) => (
                  <Button
                    onClick={() => setPlantedBy(name)}
                    key={name}
                    size="small"
                    color="inherit"
                    variant={plantedBy === name ? "contained" : "outlined"}
                    sx={{ borderRadius: "14px", margin: "5px" }}
                    disabled={dialogLoading}
                  >
                    {name}
                  </Button>
                ))}
              </div>
              <p className="AddPlantDialogBottomItemText">PLANTOR</p>
              <div className="AddPlantDialogTop">
                {plants.map((plant, index) => (
                  <Button
                    onClick={() => handleAddPlantToArr(index)}
                    key={plant.name[0]}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderRadius: "14px", margin: "5px" }}
                    disabled={dialogLoading}
                  >
                    {plant.name[0]}
                  </Button>
                ))}
              </div>

              <div className="AddPlantDialogBottom">
                {addedPlants.map((addedPlant, plantIndex) => (
                  <div
                    key={addedPlant.plant.name[0]}
                    className="AddPlantDialogBottomItem"
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleRemovePlantFromArr(plantIndex)}
                      color="inherit"
                      disabled={dialogLoading}
                    >
                      <DeleteIcon color="inherit" />
                    </IconButton>
                    <img
                      src={addedPlant.plant.url}
                      alt={addedPlant.plant.name[0]}
                      width={80}
                      height={80}
                    />
                    <p className="AddPlantDialogBottomItemText">
                      {addedPlant.plant.name[0]}
                    </p>
                    <div className="AddPlantDialogBottomItemCount">
                      <IconButton
                        onClick={() => removeAmount(plantIndex)}
                        color="inherit"
                        disabled={dialogLoading}
                      >
                        <RemoveIcon color="inherit" />
                      </IconButton>
                      <p>{addedPlant.amount}</p>
                      <IconButton
                        onClick={() => addAmount(plantIndex)}
                        color="inherit"
                        disabled={dialogLoading}
                      >
                        <AddIcon color="inherit" />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </DialogContent>
          <DialogActions sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disabled={dialogLoading}
                label="Datum"
                value={date}
                onChange={(newValue) => {
                  //@ts-ignore
                  setDate(newValue.valueOf());
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <Button
              onClick={addToFirebase}
              disabled={
                addedPlants.length <= 0 || date === null || plantedBy === null
              }
              sx={{ marginLeft: "20px", marginRight: "20px" }}
              color="inherit"
            >
              {dialogLoading ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                "Spara"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}

export default AddPlant;
