import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { firebaseConfig } from "../../.config/config";
import moment from "moment";

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDB = getFirestore(firebaseApp);
export const firebaseDatabase = getDatabase(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);

/*
export const getDates = async ({plant}: DatesProps) => {
  const dbRef = collection(firebaseDB, 'microgreens', plant, 'harvestData');

  const startDate = Date.now() - 17776000000;

  let tempArr = [];
  const q = query(dbRef, where("harvest", ">", startDate));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    tempArr.push(doc.data());
  });
  return tempArr;
};

export const getPlant = async (plant, date) => {
  let tempPlant;
  let darkPh = [];
  let darkPhDates = [];
  let lightPh = [];
  let lightPhDates = [];
  let tempEc = [];
  let tempEcDates = [];
  let tempDarkEc = [];
  let tempDarkEcDates = [];
  let tempDark = [];
    let tempDarkDates = [];
    let tempLight = [];
    let tempLightDates = [];
    let tempDarkWater = [];
    let tempDarkWaterDates = [];
    let tempLightWater = [];
    let tempLightWaterDates = [];
    let humDark = [];
    let humDarkDates = [];
    let humLight = [];
    let humLightDates = [];
    let coolTemp;


  const dbRef = doc(firebaseDB, 'microgreens', plant, 'harvestData', date);
  const docSnap = await getDoc(dbRef);
  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    tempPlant = docSnap.data();

    // Light pH
    const phRef = collection(firebaseDB, 'sensors', tempPlant.ph.toString(), 'sensorData');
    const phQ = query(
      phRef,
      where("time", ">", tempPlant.lightActive),
      where("time", "<", tempPlant.harvest)
    );
    const phSnapshot = await getDocs(phQ);
    phSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      lightPhDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      lightPh.push(doc.data().value);
    });

    //darkPh
    const phDarkRef = collection(firebaseDB, 'sensors', 'ph3', 'sensorData');
    const phDarkQ = query(
      phDarkRef,
      where("time", ">", tempPlant.seed),
      where("time", "<", tempPlant.lightActive)
    );
    const phDarkSnapshot = await getDocs(phDarkQ);
    phDarkSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      darkPhDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      darkPh.push(doc.data().value);
    });

    // EC Light
    const ecRef = collection(firebaseDB, 'sensors', tempPlant.ec.toString(), 'sensorData');
    const ecQ = query(
      ecRef,
      where("time", ">", tempPlant.lightActive),
      where("time", "<", tempPlant.harvest)
    );
    const ecSnapshot = await getDocs(ecQ);
    ecSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      tempEcDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempEc.push(doc.data().value);
    });

    // EC Dark
    const ecDarkRef = collection(firebaseDB, 'sensors', 'ec3', 'sensorData');
    const ecDarkQ = query(
      ecDarkRef,
      where("time", ">", tempPlant.seed),
      where("time", "<", tempPlant.lightActive)
    );
    const ecDarkSnapshot = await getDocs(ecDarkQ);
    ecDarkSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      tempDarkEcDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempDarkEc.push(doc.data().value);
    });

    // Temp Light
    const tempRef = collection(firebaseDB, 'sensors', tempPlant.temp.toString(), 'sensorData');
      const tempQ = query(
          tempRef,
          where("time", ">", tempPlant.lightActive),
          where("time", "<", tempPlant.harvest)
      );
      const tempSnapshot = await getDocs(tempQ);
      tempSnapshot.forEach((doc) => {
        tempLightDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
        tempLight.push(doc.data().value);
      });

    // Temp Dark
    const tempDarkRef = collection(firebaseDB, 'sensors', 'temp3', 'sensorData');
    const tempDarkQ = query(
      tempDarkRef,
      where("time", ">", tempPlant.seed),
      where("time", "<", tempPlant.lightActive)
    );
    const tempDarkSnapshot = await getDocs(tempDarkQ);
    tempDarkSnapshot.forEach((doc) => {
      tempDarkDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempDark.push(doc.data().value);
    });

    // Hum Light
    const humRef = collection(firebaseDB, 'sensors', tempPlant.hum.toString(), 'sensorData');
      const humQ = query(
          humRef,
          where("time", ">", tempPlant.lightActive),
          where("time", "<", tempPlant.harvest)
      );
      const humSnapshot = await getDocs(humQ);
      humSnapshot.forEach((doc) => {
        humLightDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
        humLight.push(doc.data().value);
      });

    // Hum Dark
    const humDarkRef = collection(firebaseDB, 'sensors', 'hum3', 'sensorData');
    const humDarkQ = query(
      humDarkRef,
      where("time", ">", tempPlant.seed),
      where("time", "<", tempPlant.lightActive)
    );
    const humDarkSnapshot = await getDocs(humDarkQ);
    humDarkSnapshot.forEach((doc) => {
      humDarkDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      humDark.push(doc.data().value);
    });

    // Water Light
    const waterRef = collection(firebaseDB, 'sensors', 'waterTemp1', 'sensorData');
      const waterQ = query(
          waterRef,
          where("time", ">", tempPlant.lightActive),
          where("time", "<", tempPlant.harvest)
      );
      const waterSnapshot = await getDocs(waterQ);
      waterSnapshot.forEach((doc) => {
        tempLightWater.push(moment(doc.data().time).format("MM-DD HH:mm"));
        tempLightWaterDates.push(doc.data().value);
      });
    // Water Dark
    const waterDarkRef = collection(firebaseDB, 'sensors', 'waterTemp3', 'sensorData');
    const waterDarkQ = query(
      waterDarkRef,
      where("time", ">", tempPlant.seed),
      where("time", "<", tempPlant.lightActive)
    );
    const waterDarkSnapshot = await getDocs(waterDarkQ);
    waterDarkSnapshot.forEach((doc) => {
      tempDarkWater.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempDarkWaterDates.push(doc.data().value);
    });

    // Temp Cooler
      let coolSum = 0;
      let coolCount = 0;
    const coolRef = collection(firebaseDB, 'sensors', 'cool1', 'sensorData');
      const coolQ = query(
          coolRef,
          where("time", ">", tempPlant.lightActive),
          where("time", "<", tempPlant.delivery)
      );
      const coolSnapshot = await getDocs(coolQ);
      coolSnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          coolSum += doc.data().value
          coolCount += 1
      });

      coolTemp = coolSum / coolCount

    return {
      data: tempPlant,
      darkPh: darkPh,
      darkPhDates: darkPhDates,
      ec: tempEc,
      ecDates: tempEcDates,
      darkEc: tempDarkEc,
      darkEcDates: tempDarkEcDates,
      lightPh: lightPh,
      lightPhDates: lightPhDates,
      tempDark: tempDark,
      tempLight: tempLight,
      tempDarkDates: tempDarkDates,
        tempLightDates: tempLightDates,
        humDarkDates: humDarkDates,
        humDark: humDark,
        humLightDates: humLightDates,
        humLight: humLight,
      tempDarkWater: tempDarkWaterDates,
        tempDarkWaterDates: tempDarkWater,
      tempLightWater: tempLightWaterDates,
        tempLightWaterDates: tempLightWater,
        coolTemp: coolTemp



    };
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};
*/