import React, { useEffect, useState } from "react";
import {
  getAuth,
  signOut,
  browserSessionPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  collectionGroup,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { firebaseDB } from "../trace/firestore";
import { plantInfoNew } from "../../components/utils/enums";

import {
  ActivePlantArrayIF,
  HarvestPlantIF,
  HarvestPlantArrayIF,
  ActivePlantIF,
  PlantArrayIF,
} from "../../components/interfaces/Interfaces";

import HarvestItem from "./harvest/HarvestItem";
import "./dashboard.css";

const DashBoardHarvest = () => {
  const [firebaseLoading, setFirebaseLoading] = useState(false);
  const [activePlants, setActivePlants] = useState<HarvestPlantArrayIF | null>(
    null
  );
  const [openEditContent, setOpenEditContent] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);


  const handleEditOpen = (plantIndex: number) => {
    setOpenEditContent(plantIndex);
    setOpenEdit(true);
  };

const handleCheckChange = (event: boolean, id: string) => {
    if (activePlants === null) {
        return
    }
    let tempPlants = [...activePlants];
    // @ts-ignore
    tempPlants.find((element) => element.firebaseId === id).checked = event;
    setActivePlants(tempPlants);
  };
  useEffect(() => {
    const plantQuery = query(
      collectionGroup(firebaseDB, "harvestData"),
      where("end", "==", null)
    );
    const unsubscribe = onSnapshot(plantQuery, (querySnapshot) => {
      setFirebaseLoading(true);
      let tempPlants: HarvestPlantArrayIF = [];
      querySnapshot.forEach((doc) => {
        let tempObj = { firebaseId: doc.id, checked: false, parentData: plantInfoNew.find(element => element.id === doc.data().parentId ), ...doc.data() };
        console.log(tempObj)
        //@ts-ignore
        tempPlants.push(tempObj);
      });
      setActivePlants(tempPlants);
      setFirebaseLoading(false);
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, []);
  return (
    <div className="DashBoardTabInner">
      {activePlants !== null ? (
        <div className="DashboardActive">
          {activePlants.map((item, index) => (
            <HarvestItem
            plant={item}
            handleCheckChange={handleCheckChange}
            handleEditOpen={handleEditOpen}
            harvestIndex={index}
            key={
              item.firebaseId
            }
          />
          ))}
        </div>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </div>
  );
};

export default DashBoardHarvest;
