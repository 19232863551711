import React, { useEffect, useState } from "react";

import {
  doc,
  deleteDoc,
  collection,
  addDoc,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import LightItem from "../../../components/dashboard/LightItem";
import ChangeTrayAmount from "../../../components/dashboard/ChangeTrayAmount";
import ChangePlantedBy from "../../../components/dashboard/ChangePlantedBy";
import ChangeDate from "../../../components/dashboard/ChangeDate";
import LightHarvestItems from "../../../components/dashboard/LightHarvestItem";

import { firebaseAuth } from "../../../components/firebase/auth";
import { firebaseDB } from "../../trace/firestore";
import { plantInfoNew } from "../../../components/utils/enums";

import {
  ActivePlantArrayIF,
  ActivePlantHarvestArrayIF,
} from "../../../components/interfaces/Interfaces";
import "./dashboardActive.css";

const plantedByArr = [
  "Ann-Helen",
  "Adrian",
  "Jimmy",
  "Ann-Helen & Adrian",
  "Ann-Helen & Jimmy",
  "Adrian & Jimmy",
  "Ann-Helen, Adrian & Jimmy",
];

type TsProps = {
  plantArr: ActivePlantArrayIF;
};
const LightPlants = ({ plantArr }: TsProps) => {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditContent, setOpenEditContent] = useState<number | null>(null);
  const [openMove, setOpenMove] = useState(false);
  const [tempArr, setTempArr] = useState<ActivePlantArrayIF>([]);
  const [dateChoosen, setDateChosen] = useState<number | null>(null);
  const [harvestBy, setHarvestBy] = useState<string | null>(null);
  const [harvestAmount, setHarvestAmount] = useState<number[]>([]);
  const [harvestArr, sertHarvestArr] = useState<ActivePlantHarvestArrayIF>([]);

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleMoveClose = () => {
    setOpenMove(false);
  };

  const handleMoveOpen = () => {
    let arrToSet = [];
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].checked === true) {
        const tempPlantInfo = plantInfoNew.find((element) => element.id === tempArr[i].id)
        let tempHarvest = [];
         // @ts-ignore
        for (let j = 0; j < tempPlantInfo.size.length; j++) {
          let tempHarvestObj = {
            // @ts-ignore
            size: tempPlantInfo.size[j],
            amount: 0,
          }
          if (j === 0 ) {
            // @ts-ignore
            tempHarvestObj.amount = Math.floor((tempPlantInfo.tray/tempPlantInfo.size[j]*tempArr[i].amount))
          } 
          tempHarvest.push(tempHarvestObj)
        }
        arrToSet.push({
          amount: tempArr[i].amount,
          plant: tempArr[i],
          harvestAmount: tempHarvest
        });
      }
    }
    sertHarvestArr(arrToSet);
    setOpenMove(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditOpen = (plantIndex: number) => {
    setOpenEditContent(plantIndex);
    setOpenEdit(true);
  };

  const handleCheckChange = (event: boolean, id: string) => {
    let tempPlants = [...tempArr];
    // @ts-ignore
    tempPlants.find((element) => element.firebaseId === id).checked = event;
    setTempArr(tempPlants);
  };

  const anyItemChecked = () => {
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].checked === true) {
        return true;
      }
    }
    return false;
  };

  const deletePlant = async () => {
    setOpenDelete(false);
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].checked === true) {
        await deleteDoc(
          doc(
            firebaseDB,
            "plants",
            tempArr[i].id,
            "plantData",
            tempArr[i].firebaseId
          )
        );
      }
    }
  };

  const movePlant = async () => {
    if (dateChoosen === null) {
      return;
    }
    setOpenMove(false);
    for (let i = 0; i < harvestArr.length; i++) {
      await addDoc(
        collection(firebaseDB, "plants", harvestArr[i].plant.id, "harvestData"),
        {
          parent: harvestArr[i].plant.firebaseId,
          amount: harvestArr[i].amount,
          date: dateChoosen,
          harvestBy: harvestBy,
          harvestAmount: harvestArr[i].harvestAmount,
          parentId: harvestArr[i].plant.id,
          end: null
        }
      );
      if (harvestArr[i].amount >= harvestArr[i].plant.amount) {
        await updateDoc(
          doc(
            firebaseDB,
            "plants",
            tempArr[i].id,
            "plantData",
            tempArr[i].firebaseId
          ),
          {
            end: Date.now(),
            amount: 0,
          }
        );
      } else {
        await updateDoc(
          doc(
            firebaseDB,
            "plants",
            tempArr[i].id,
            "plantData",
            tempArr[i].firebaseId
          ),
          {
            amount: harvestArr[i].plant.amount - harvestArr[i].amount,
          }
        );
      }
    }
  };

  useEffect(() => {
    if (plantArr.length > 0) {
      setTempArr(plantArr);
    }
  }, [plantArr]);

  return (
    <div className="DarkPlants">
      <div className="DarkPlantsHeader">
        <p className="PlantListTitle">Ljus</p>
        {anyItemChecked() ? (
          <Button
            sx={{ marginLeft: "10px", marginRight: "10px" }}
            color="inherit"
            variant="outlined"
            size="small"
            onClick={() => setOpenDelete(true)}
          >
            Radera
          </Button>
        ) : null}
        {anyItemChecked() ? (
          <Button
            sx={{ marginLeft: "10px" }}
            color="inherit"
            variant="outlined"
            size="small"
            onClick={handleMoveOpen}
          >
            Skörda
          </Button>
        ) : null}
      </div>
      <div className="AddPlantDialogBottom">
        {tempArr.map((addedPlant, plantIndex) => (
          <LightItem
            plant={addedPlant}
            handleCheckChange={handleCheckChange}
            handleEditOpen={handleEditOpen}
            plantIndex={plantIndex}
            key={addedPlant.firebaseId}
          />
        ))}
      </div>
      <Dialog open={openDelete} onClose={handleDeleteClose}>
        <DialogTitle>
          Radera?
          {"         "}
          {"         "}
        </DialogTitle>
        <DialogActions sx={{ color: "#44a99a" }}>
          <Button
            sx={{ margin: "10px" }}
            color="inherit"
            onClick={handleDeleteClose}
          >
            Nej
          </Button>
          <Button
            sx={{ margin: "10px" }}
            color="inherit"
            onClick={deletePlant}
            autoFocus
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openMove} onClose={handleMoveClose}>
        <DialogTitle>
          Skörda?
          {"         "}
          {"         "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ange datum, antal fat som skördats och av vem!
          </DialogContentText>
          <div className="AddPlantDialog">
            <p className="AddPlantDialogBottomItemText">SKÖRDAD AV</p>
            <div className="AddPlantDialogTop">
              {plantedByArr.map((name, index) => (
                <Button
                  onClick={() => setHarvestBy(name)}
                  key={name}
                  size="small"
                  color="inherit"
                  variant={harvestBy === name ? "contained" : "outlined"}
                  sx={{ borderRadius: "14px", margin: "5px" }}
                >
                  {name}
                </Button>
              ))}
            </div>
            <LightHarvestItems
              plants={harvestArr}
              sertHarvestArr={sertHarvestArr}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ color: "#44a99a" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Datum"
              value={dateChoosen}
              onChange={(newValue) => {
                //@ts-ignore
                setDateChosen(newValue.valueOf());
              }}
              renderInput={(params) => (
                <TextField sx={{ margin: "10px" }} size="small" {...params} />
              )}
            />
          </LocalizationProvider>
          <Button
            sx={{ margin: "10px" }}
            disabled={dateChoosen === null || harvestBy === null}
            color="inherit"
            onClick={movePlant}
            autoFocus
          >
            Skörda
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={handleEditClose}>
        <DialogTitle>Redigera</DialogTitle>
        <DialogContent>
          {openEditContent !== null ? (
            <div className="AddPlantDialog">
              <p className="AddPlantDialogBottomItemText">PLANTERAD AV</p>
              <ChangePlantedBy plant={tempArr[openEditContent]} />
              <p className="AddPlantDialogBottomItemText">Antal</p>
              <ChangeTrayAmount plant={tempArr[openEditContent]} />
              <p className="AddPlantDialogBottomItemText">Planterad</p>
              <ChangeDate plant={tempArr[openEditContent]} isSeed={true} />
              <p className="AddPlantDialogBottomItemText">Till Ljus</p>
              <ChangeDate plant={tempArr[openEditContent]} isSeed={false} />
            </div>
          ) : null}
        </DialogContent>
        <DialogActions sx={{ color: "#44a99a" }}></DialogActions>
      </Dialog>
    </div>
  );
};

export default LightPlants;
