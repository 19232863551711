import React, { useEffect, useState, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { getDatabase, ref as refDB, set, remove } from "firebase/database";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";

import { firebaseStorage, firebaseDatabase } from "../../trace/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { DocumentIF } from "../../../components/interfaces/Interfaces";
import "./documents.css";

type TsProps = {
  document: DocumentIF;
};
function DocumentItem(props: TsProps) {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [openDelete, setOpenDelete] = useState(false);

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const deleteItem = async () => {
    try {
      const fileRef = ref(
        firebaseStorage,
        "documents/" + props.document.fileName
      );
      await deleteObject(fileRef);
      await remove(refDB(firebaseDatabase, 'documents/' + props.document.id))
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="DocumentItem">
      <div className="DocumentItemInner">
        <p className="DocumentItemTitle">{props.document.name}</p>
        <a
          href={props.document.url}
          target="_blank"
          rel="noreferrer"
          className="DocumentItemSubtitle"
        >
          {props.document.fileName}
        </a>
      </div>
      <div className="DocumentItemIcons">
        <IconButton onClick={() => setOpenDelete(true)} color="inherit" size="large">
          <DeleteIcon />
        </IconButton>
        <IconButton color="inherit" size="large" onClick={() =>  navigator.clipboard.writeText(props.document.url)}>
          <ContentCopyIcon />
        </IconButton>
      </div>
      <Dialog open={openDelete} onClose={handleDeleteClose}>
        <DialogTitle>
          Radera?
          {"         "}
          {"         "}
        </DialogTitle>
        <DialogActions sx={{ color: "#44a99a" }}>
          <Button
            sx={{ margin: "10px" }}
            color="inherit"
            onClick={handleDeleteClose}
          >
            Nej
          </Button>
          <Button
            sx={{ margin: "10px" }}
            color="inherit"
            onClick={deleteItem}
            autoFocus
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DocumentItem;
