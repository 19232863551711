import { useEffect, useState } from "react";
import logo from "../../static/social.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { firebaseAuth, firebaseInitAuth } from "../../components/firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signOut,
  browserSessionPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import "./auth.css";

const textfieldStyle = {
  marginBottom: "20px",
  color: "#44a99a",
  borderColor: "#44a99a",
  justifySelf: "center",
  opacity: 0.99,
  "& label.Mui-focused": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& .Mui-disabled": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "#44a99a",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      borderColor: "#44a99a",
    },
    "& input": {
      borderColor: "#44a99a",
      WebkitTextFillColor: "unset",
    },
    "& label": {
      color: "#44a99a",
    },
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& label": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& textarea": {
    color: "#000",
    borderColor: "#44a99a",
  },
  "& input": {
    color: "#000",
    borderColor: "#44a99a",
  },
  "& .MuiInput-underline:after": {
    borderColor: "#44a99a",
    color: "#44a99a",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
    "&:hover fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
  },
};

function AuthView() {
  const [user, loading, error] = useAuthState(firebaseAuth);
    const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [authLoading, setAuthLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const initLogin = async () => {
    setAuthLoading(true);
    signInWithEmailAndPassword(firebaseAuth, username, password)
      .then(() => {
        setAuthLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setAuthLoading(false);
      });
  };

    useEffect(() => {
        console.log('Check user')
        if (user === null && loading === false) {
            console.log('no user')
            setAuthLoading(false)
        } else if (user !== null && loading === false) {
            console.log('user found')
            navigate("/dashboard");
        }
    }, [loading])

  useEffect(() => {
      if (user !== null) {
          navigate("/dashboard");
      } 
  }, [user])
  console.log(user);
  console.log(loading);
  console.log(error);
  return (
    <div className="AuthView">
      <img src={logo} className="App-logo" alt="logo" />
      {authLoading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className="AuthViewForm">
          <TextField
            sx={textfieldStyle}
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            type="email"
            size="small"
            className="AuthViewInput"
            label="Email"
            variant="outlined"
          />
          <TextField
            sx={textfieldStyle}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            type="password"
            size="small"
            className="AuthViewInput"
            label="Lösenord"
            variant="outlined"
          />
          <Button
            disabled={username === "" || password === ""}
            onClick={initLogin}
            color="inherit"
            className="AuthViewButton"
            variant="outlined"
          >
            Logga in
          </Button>
        </div>
      )}
      {errorMessage !== "" ? <p>{errorMessage}</p> : null}
    </div>
  );
}

export default AuthView;
