import React, { useEffect, useState } from "react";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { firebaseDB } from "../../views/trace/firestore";

import { ActivePlantIF } from "../interfaces/Interfaces";
import "./dashboard.css";

type TsProps = {
  plant: ActivePlantIF;
};

const ChangeTrayAmount = ({ plant }: TsProps) => {

  const addAmount = async () => {
    console.log('+')
    if (plant.amount >= 50) {
      return;
    }
    await updateDoc(
      doc(firebaseDB, "plants", plant.id, "plantData", plant.firebaseId),
      {
        amount: plant.amount + 1,
      }
    );
  };

  const removeAmount = async () => {
    console.log('-')
    if (plant.amount <= 1) {
      return;
    }
    await updateDoc(
      doc(firebaseDB, "plants", plant.id, "plantData", plant.firebaseId),
      {
        amount: plant.amount - 1,
      }
    );
  };

  return (
    <div className="AddPlantDialogBottomItemCount">
      <IconButton onClick={() => removeAmount()} color="inherit">
        <RemoveIcon color="inherit" />
      </IconButton>
      <p className="DarkPlantAmountText">{plant.amount}</p>
      <IconButton onClick={() => addAmount()} color="inherit">
        <AddIcon color="inherit" />
      </IconButton>
    </div>
  );
};

export default ChangeTrayAmount;
