import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";

import { firebaseAuth } from "../../components/firebase/auth";
import "../../App.css";

function Start() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    if (user === null && loading === false) {
      navigate("/login");
      setAuthLoading(false);
    } else if (user !== null && loading === false) {
      navigate("/dashboard");
      setAuthLoading(false);
    }
  }, [loading]);

  return (
    <div className="Start">
      {authLoading ? <CircularProgress color="inherit" /> : null}
    </div>
  );
}

export default Start;
