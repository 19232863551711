import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import { plantInfoNew } from "../../../components/utils/enums";
import { firebaseAuth } from "../../../components/firebase/auth";

import { ActivePlantIF, PlantArrayIF, HarvestPlantIF } from "../../../components/interfaces/Interfaces";
import "./harvest.css";

type TsProps = {
  plant: HarvestPlantIF;
  handleCheckChange(arg0: boolean, arg1: string): void;
  handleEditOpen(arg0: number): void;
  harvestIndex: number;
};

const HarvestItem = ({
  plant,
  handleCheckChange,
  handleEditOpen,
  harvestIndex,
}: TsProps) => {

  return (
    <div className="AddPlantDialogBottomItem">
      <Checkbox
        size="small"
        sx={{
          color: "grey",
          "&.Mui-checked": {
            color: "#44a99a",
          },
        }}
        checked={plant.checked}
        onChange={(event) => handleCheckChange(event.target.checked, plant.firebaseId)}
        inputProps={{ "aria-label": "controlled" }}
      />
      <img
        src={
          plant.parentData.url
        }
        alt={
          plant.parentData.name[0]
        }
        width={80}
        height={80}
      />
      <div className="PlantListItemTextOuter">
        <p className="AddPlantDialogBottomItemText">
          {
            plant.parentData.name[0]
          }
        </p>
      </div>
      <div className="PlantListItemDays">
        <p className="PlantListItemDaysLarge">
          {Math.floor((Date.now() - Number(plant.date)) / 86400000)}
        </p>
        <p className="PlantListItemDaysSmall">DAGAR</p>
      </div>
      <IconButton color="inherit" onClick={() => handleEditOpen(harvestIndex)}>
        <EditIcon color="inherit" />
      </IconButton>
    </div>
  );
};

export default HarvestItem;
