import React, { useEffect, useState } from "react";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { firebaseDB } from "../../views/trace/firestore";

import { ActivePlantIF } from "../interfaces/Interfaces";
import "./dashboard.css";

type TsProps = {
  plant: ActivePlantIF;
  isSeed: boolean;
};

const plantedByArr = [
  "Ann-Helen",
  "Adrian",
  "Jimmy",
  "Ann-Helen & Adrian",
  "Ann-Helen & Jimmy",
  "Adrian & Jimmy",
  "Ann-Helen, Adrian & Jimmy",
];

const ChangeDate = ({ plant, isSeed }: TsProps) => {
  const uodateDate = async (newDate: number) => {
    if (isSeed) {
      if (plant.planted === newDate) {
        return;
      }

      await updateDoc(
        doc(firebaseDB, "plants", plant.id, "plantData", plant.firebaseId),
        {
          planted: newDate,
        }
      );
    } else if (!isSeed) {
      if (plant.planted === newDate) {
        return;
      }

      await updateDoc(
        doc(firebaseDB, "plants", plant.id, "plantData", plant.firebaseId),
        {
          lightActive: newDate,
        }
      );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label="Datum"
        value={isSeed ? plant.planted:plant.lightActive}
        onChange={(newValue) => {
          //@ts-ignore
          uodateDate(newValue.valueOf());
        }}
        renderInput={(params) => (
          <TextField sx={{ margin: "10px" }} size="small" {...params} />
        )}
      />
    </LocalizationProvider>
  );
};

export default ChangeDate;
