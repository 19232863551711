import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { firebaseAuth } from "../../components/firebase/auth";
import "./dashboard.css";

function DashBoardOrders() {
    const [user, loading, error] = useAuthState(firebaseAuth);
    const navigate = useNavigate();


    return (
        <div className="DashBoardTabInner">
            DashBoardOrders
        </div>
    );
}

export default DashBoardOrders;
