import React, { useEffect, useState } from "react";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { firebaseAuth } from "../../../components/firebase/auth";
import { firebaseDB } from "../../trace/firestore";
import { plantInfoNew } from "../../../components/utils/enums";

import DarkItem from "../../../components/dashboard/DarkItem";
import ChangeTrayAmount from "../../../components/dashboard/ChangeTrayAmount";
import ChangePlantedBy from "../../../components/dashboard/ChangePlantedBy";
import ChangeDate from "../../../components/dashboard/ChangeDate";

import { ActivePlantArrayIF } from "../../../components/interfaces/Interfaces";
import "./dashboardActive.css";

const plantedByArr = [
  "Ann-Helen",
  "Adrian",
  "Jimmy",
  "Ann-Helen & Adrian",
  "Ann-Helen & Jimmy",
  "Adrian & Jimmy",
  "Ann-Helen, Adrian & Jimmy",
];

type TsProps = {
  plantArr: ActivePlantArrayIF;
};

const DarkPlants = ({ plantArr }: TsProps) => {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditContent, setOpenEditContent] = useState<number | null>(null);
  const [openMove, setOpenMove] = useState(false);
  const [tempArr, setTempArr] = useState<ActivePlantArrayIF>([]);
  const [dateChoosen, setDateChosen] = useState<number | null>(null);

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleMoveClose = () => {
    setOpenMove(false);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditOpen = (plantIndex: number) => {
    setOpenEditContent(plantIndex);
    setOpenEdit(true);
  };

  const handleCheckChange = (event: boolean, id: string) => {
    let tempPlants = [...tempArr];
    // @ts-ignore
    tempPlants.find((element) => element.firebaseId === id).checked = event;
    setTempArr(tempPlants);
  };

  const anyItemChecked = () => {
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].checked === true) {
        return true;
      }
    }
    return false;
  };

  const deletePlant = async () => {
    setOpenDelete(false);
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].checked === true) {
        await deleteDoc(
          doc(
            firebaseDB,
            "plants",
            tempArr[i].id,
            "plantData",
            tempArr[i].firebaseId
          )
        );
      }
    }
  };

  const movePlant = async () => {
    if (dateChoosen === null) {
      return
    }
    setOpenMove(false);
    for (let i = 0; i < tempArr.length; i++) {
      if (tempArr[i].checked === true) {
        await updateDoc(
          doc(
            firebaseDB,
            "plants",
            tempArr[i].id,
            "plantData",
            tempArr[i].firebaseId
          ),
          {
            lightActive: dateChoosen,
          }
        );
      }
    }
  };

  useEffect(() => {
    if (plantArr.length > 0) {
      setTempArr(plantArr);
    }
  }, [plantArr]);

  return (
    <div className="DarkPlants">
      <div className="DarkPlantsHeader">
        <p className="PlantListTitle">Mörker</p>
        {anyItemChecked() ? (
          <Button
            sx={{ marginLeft: "10px", marginRight: "10px" }}
            color="inherit"
            variant="outlined"
            size="small"
            onClick={() => setOpenDelete(true)}
          >
            Radera
          </Button>
        ) : null}
        {anyItemChecked() ? (
          <Button
            sx={{ marginLeft: "10px" }}
            color="inherit"
            variant="outlined"
            size="small"
            onClick={() => setOpenMove(true)}
          >
            Till Ljus
          </Button>
        ) : null}
      </div>
      <div className="AddPlantDialogBottom">
        {tempArr.map((addedPlant, plantIndex) => (
          <DarkItem
            plant={addedPlant}
            handleCheckChange={handleCheckChange}
            handleEditOpen={handleEditOpen}
            plantIndex={plantIndex}
            key={
              addedPlant.firebaseId
            }
          />
        ))}
      </div>
      <Dialog open={openDelete} onClose={handleDeleteClose}>
        <DialogTitle>
          Radera?
          {"         "}
          {"         "}
        </DialogTitle>
        <DialogActions sx={{ color: "#44a99a" }}>
          <Button
            sx={{ margin: "10px" }}
            color="inherit"
            onClick={handleDeleteClose}
          >
            Nej
          </Button>
          <Button
            sx={{ margin: "10px" }}
            color="inherit"
            onClick={deletePlant}
            autoFocus
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openMove} onClose={handleMoveClose}>
        <DialogTitle>
          Flytta till Ljus? Ange först datum!
          {"         "}
          {"         "}
        </DialogTitle>
        <DialogActions sx={{ color: "#44a99a" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Datum"
              value={dateChoosen}
              onChange={(newValue) => {
                //@ts-ignore
                setDateChosen(newValue.valueOf());
              }}
              renderInput={(params) => (
                <TextField sx={{ margin: "10px" }} size="small" {...params} />
              )}
            />
          </LocalizationProvider>
          <Button
            sx={{ margin: "10px" }}
            disabled={dateChoosen === null}
            color="inherit"
            onClick={movePlant}
            autoFocus
          >
            Flytta
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={handleEditClose}>
        <DialogTitle>Redigera</DialogTitle>
        <DialogContent>
          {openEditContent !== null ? (
            <div className="AddPlantDialog">
              <p className="AddPlantDialogBottomItemText">PLANTERAD AV</p>
              <ChangePlantedBy plant={tempArr[openEditContent]}/>
              <p className="AddPlantDialogBottomItemText">Antal</p>
              <ChangeTrayAmount plant={tempArr[openEditContent]}/>
              <p className="AddPlantDialogBottomItemText">Planterad</p>
              <ChangeDate plant={tempArr[openEditContent]} isSeed={true} />
            </div>
          ) : null}
        </DialogContent>
        <DialogActions sx={{ color: "#44a99a" }}></DialogActions>
      </Dialog>
    </div>
  );
};

export default DarkPlants;

/* 
<div
            key={
              plantInfoNew.find((element) => element.id === addedPlant.id)
                .name[0]
            }
            className="AddPlantDialogBottomItem"
          >
            <Checkbox
              size="small"
              sx={{
                color: "grey",
                "&.Mui-checked": {
                  color: "#44a99a",
                },
              }}
              checked={addedPlant.checked}
              onChange={(event) => handleCheckChange(event, addedPlant.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <img
              src={
                plantInfoNew.find((element) => element.id === addedPlant.id).url
              }
              alt={
                plantInfoNew.find((element) => element.id === addedPlant.id)
                  .name[0]
              }
              width={80}
              height={80}
            />
            <div className="PlantListItemTextOuter">
              <p className="AddPlantDialogBottomItemText">
                {
                  plantInfoNew.find((element) => element.id === addedPlant.id)
                    .name[0]
                }
              </p>
              <p className="PlantListSubtitle">
                {addedPlant.amount} Fat - Planterad av {addedPlant.seedBy} för{" "}
                {Math.floor((Date.now() - Number(addedPlant.seed)) / 86400000)}{" "}
                dagar sedan
              </p>
            </div>
            <div className="PlantListItemDays">
              <p className="PlantListItemDaysLarge">
                {Math.floor((Date.now() - Number(addedPlant.seed)) / 86400000)}
              </p>
              <p className="PlantListItemDaysSmall">DAGAR</p>
            </div>
            <IconButton
              color="inherit"
              onClick={() => handleEditOpen(plantIndex)}
            >
              <EditIcon color="inherit" />
            </IconButton>
          </div>*/
