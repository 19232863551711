import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import ControlDateItem from "./control/ControlDateItem";
import ControlValueItem from "./control/ControlValueItem";
import { monitorArray } from "../../components/utils/enums";
import { firebaseAuth } from "../../components/firebase/auth";
import "./dashboard.css";

function DashBoardControl() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  return (
    <div className="DashBoardTabInner">
      {monitorArray.map((outerItem, outerIndex) => (
        <div className="DashBoardControlOuter">
             <div className="DarkPlantsHeader">
        <p className="PlantListTitle">{outerItem.title}</p>
        
      </div>
          {outerItem.data.map((item, index) => {
            if (item.type === "date") {
              return <ControlDateItem key={index} item={item} />;
            } else {
              return <ControlValueItem key={index} item={item} />;
            }
          })}
        </div>
      ))}
    </div>
  );
}

export default DashBoardControl;
