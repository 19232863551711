import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { plantInfoNew } from "../utils/enums";

import { ActivePlantHarvestArrayIF } from "../interfaces/Interfaces";
import "./dashboard.css";

type TsProps = {
  plants: ActivePlantHarvestArrayIF;
  sertHarvestArr(arg0: ActivePlantHarvestArrayIF): void;
};

const LightHarvestItems = ({ plants, sertHarvestArr }: TsProps) => {
  const addAmount = (plantIndex: number) => {
    let tempArr = [...plants];

    if (tempArr[plantIndex].amount >= tempArr[plantIndex].plant.amount) {
      return;
    }

    tempArr[plantIndex].amount += 1;

    sertHarvestArr(tempArr);
  };

  const removeAmount = (plantIndex: number) => {
    let tempArr = [...plants];

    if (tempArr[plantIndex].amount <= 1) {
      return;
    }
    tempArr[plantIndex].amount -= 1;
    sertHarvestArr(tempArr);
  };

  const setSliderValue = (plantIndex: number, value:number, harvestIndex:number) => {
    let tempArr = [...plants];

    tempArr[plantIndex].harvestAmount[harvestIndex].amount = value;
    sertHarvestArr(tempArr);
  };

  console.log(plants);
  return (
    <div className="AddPlantDialogBottom">
      {plants.map((plant, plantIndex) => (
        <div key={plant.plant.firebaseId} className="HarvestDialogItemOuter">
          <div className="HarvestDialogItem">
            <img
              src={
                // @ts-ignore
                plantInfoNew.find((element) => element.id === plant.plant.id)
                  .url
              }
              alt={
                // @ts-ignore
                plantInfoNew.find((element) => element.id === plant.plant.id)
                  .name[0]
              }
              width={80}
              height={80}
            />
            <p className="AddPlantDialogBottomItemText">
              {
                // @ts-ignore
                plantInfoNew.find((element) => element.id === plant.plant.id)
                  .name[0]
              }
            </p>
            <div className="AddPlantDialogBottomItemCount">
              <IconButton
                onClick={() => removeAmount(plantIndex)}
                color="inherit"
              >
                <RemoveIcon color="inherit" />
              </IconButton>
              <p>{plant.amount}</p>
              <IconButton onClick={() => addAmount(plantIndex)} color="inherit">
                <AddIcon color="inherit" />
              </IconButton>
            </div>
          </div>
          <div className="HarvestDialogItemBottom">
            {plant.harvestAmount.map((harvestItem, harvestIndex) => (
              <div key={harvestIndex} className="HarvestDialogItemSlider">
                <p>{harvestItem.size}g</p>
                <Slider
                  size="small"
                  value={harvestItem.amount}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  min={0}
                  max={plant.plant.amount*15}
                  // @ts-ignore
                  onChange={(event) => setSliderValue(plantIndex, event.target.value, harvestIndex)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LightHarvestItems;
